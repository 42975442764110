.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes colorChange {
  0%, 100% {
    color: red;
  }
  50% {
    color: blue;
  }
}

/* For WebKit Browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 8px; /* Thin scrollbar */
}

::-webkit-scrollbar-track {
  background: transparent; /* Transparent track */
}

::-webkit-scrollbar-thumb {
  background-color: rgba(75, 85, 99, 0.8); /* Gray color, semi-transparent */
  border-radius: 10px; /* Rounded corners */
}

/* For Firefox */
* {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: rgba(75, 85, 99, 0.8) transparent; /* Gray color thumb, transparent track */
}

.custom-scrollbar {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: rgba(75, 85, 99, 0.8) transparent;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(75, 85, 99, 0.8);
  border-radius: 10px;
}

.animate-colorChange {
  animation: colorChange 3s ease-in-out infinite;
}

.fade-in {
  animation: fadeIn 1s ease-out forwards;
}

